import React, { useEffect, useState } from "react";
import { bool, func } from "prop-types";

import Config from "../../../../config";

import MenuLink from "../MenuLink";
import SocialMediaLink from "../../../../components/SocialMediaLink";
import {
  Instagram,
  Facebook,
  LinkedIn,
  Mail,
  User,
  House,
  Plus,
  CheckMark,
  Folder,
  Star,
  Peek,
  Heart,
  Suitcase,
  Search,
} from "../../../../libs/icons";

import backgroundImage from "./second-background.webp";
import styles from "./styles.module.scss";

const Menu = ({ visible, handleClose }) => {
  const TIMING = 250;
  const [isVisible, setVisible] = useState(visible);
  const [isActive, setActive] = useState(visible);
  const hasUserToken = localStorage.getItem("@user_token");
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;

  const getMyAccountPath = () => {
    if (!hasUserToken) {
      return "/para-empresas/autenticacao/login";
    }

    if (!userData.type) return "/candidato/minha-conta";

    if (userData.type === "PJ" && !userData.inkluer) {
      return "/para-empresas/minha-conta";
    }

    if (userData.type === "PJ") {
      return "/recrutador/minha-conta";
    }

    if (userData.type === "PF") {
      return "/candidato/minha-conta";
    }
  };

  const getMyPositions = () => {
    if (!userData.inkluer) {
      return (
        <>
          {userData.permissions?.externalHunting && (
            <MenuLink
              handleClose={handleClose}
              href="/plataforma/busca"
            >
              <Search color="#fff" /> Buscar perfis
            </MenuLink>
          )}
          <MenuLink handleClose={handleClose} to="/para-empresas/recrutador/minhas-vagas">
            <Folder color="#fff" /> Minhas Vagas
          </MenuLink>
        </>
      )
    }

    if (userData.profile === 'leader') return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/processos/recrutando"
        >
          <Folder color="#fff" /> Meu escritório
        </MenuLink>
      </>
    )

    if (userData.permissions?.externalHunting) return (
      <>

        <MenuLink
          handleClose={handleClose}
          href="/recrutador/minhas-vagas"
        >
          <Folder color="#fff" /> Minhas Vagas
        </MenuLink>
      </>
    )

    return (
      <MenuLink
        handleClose={handleClose}
        href="/recrutador/minhas-vagas"
      >
        <Folder color="#fff" /> Minhas Vagas
      </MenuLink>
    )
  };

  useEffect(() => {
    if (visible) {
      setVisible(true);
      setTimeout(() => {
        setActive(true);
      }, TIMING);
      return;
    }

    setActive(false);
    setTimeout(() => {
      setVisible(false);
    }, TIMING);
  }, [visible]);

  return (
    <div
      aria-hidden={!visible}
      tabIndex={visible ? "0" : "-1"}
      className={[
        styles.wrapper,
        isActive && styles.isVisible,
        !isVisible && styles.isHidden,
      ].join(" ")}
      style={{
        backgroundImage:
          `url(${backgroundImage})`
      }}
    >
      <button
        className={styles.closeButton}
        onClick={handleClose}
        id="close-button"
      >
        <Plus color="#fff" />
      </button>
      <nav className={styles.navigation}>
        <MenuLink handleClose={handleClose} to="/para-empresas">
          <House color="#fff" />
          Início
        </MenuLink>
        <MenuLink handleClose={handleClose} to="/para-empresas/solucoes-rs">
          <Star color="#fff" />
          Soluções para R&S
        </MenuLink>
        <MenuLink handleClose={handleClose} to="/para-empresas/mapeamento-tech">
          <CheckMark color="#fff" />
          Mapeamento Tech
        </MenuLink>
        <MenuLink handleClose={handleClose} to="/para-empresas/contato">
          <Mail color="#fff" />
          Contato
        </MenuLink>
        <span className={styles.split} />
        {userData && userData.type === "PJ" && getMyPositions()}
        <MenuLink
          handleClose={handleClose}
          href={(userData?.type === "PJ" && userData?.inkluer)
            && "/recrutador/minha-conta"
          }
          to={getMyAccountPath}
        >
          <User color="#fff" /> Minha conta
        </MenuLink>
      </nav>
      <div className={styles.footer}>
        <span className={styles.text}>Siga #inklua</span>
        <SocialMediaLink
          url={Config.INSTAGRAM_URL}
          color='#326B99'
        >
          <Instagram color="#fff" />
        </SocialMediaLink>
        <SocialMediaLink
          url={Config.FACEBOOK_URL}
          color='#326B99'
        >
          <Facebook color="#fff" />
        </SocialMediaLink>
        <SocialMediaLink
          url={Config.LINKEDIN_URL}
          color='#326B99'
          onBlur={() => document.querySelector('#close-button').focus()}
        >
          <LinkedIn color="#fff" />
        </SocialMediaLink>
      </div>
    </div>
  );
};

Menu.propTypes = {
  handleClose: func.isRequired,
  visible: bool,
};

Menu.defaultProps = {
  visible: false,
};

export default Menu;
