import React from "react";
import { Link } from "react-router-dom";

import Config from "../../config";
import SocialMediaLink from "../SocialMediaLink";
import { Instagram, Facebook, LinkedIn } from "../../libs/icons";

import Logo from "./logo-inklua-white.svg";
import styles from "./styles.module.scss";

const Footer = () => {
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;
  const hasUserToken = localStorage.getItem("@user_token");

  const getMyAccountPath = () => {
    if (!hasUserToken) {
      return "/autenticacao/login";
    };

    if (!userData.type) return "/candidato/minha-conta";

    if (userData.type === "PJ" && !userData.inkluer) {
      return "/para-empresas/minha-conta";
    }

    if (userData.type === "PF") {
      return "/candidato/minha-conta";
    }
  };

  const renderMyPositionsButton = () => {
    if (!userData.inkluer) {
      return (
        <li>
          <Link to="/para-empresas/recrutador/minhas-vagas">Minhas vagas</Link>
        </li>
      )
    };

    if (userData.profile === 'leader') {
      return (
        <li>
          <a
            href="/processos/recrutando"
            target="_blank"
            rel="noreferrer"
          >
            Meu escritório
          </a>
        </li>
      )
    }

    if (userData.profile === 'commercial') return (
      <li>
        <a
          href="/comercial"
          target="_blank"
          rel="noreferrer"
        >
          Comercial
        </a>
      </li>
    )

    return (
      <li>
        <a
          href="/recrutador/minhas-vagas"
          target="_blank"
          rel="noreferrer"
        >
          Minhas vagas
        </a>
      </li>
    );
  };

  const renderMyAccountButton = () => {
    if (userData?.type === "PJ" && userData?.inkluer) {
      return (
        <a
          href="/recrutador/minha-conta"
          target="_blank"
          rel="noreferrer"
        >
          Minha Conta
        </a>
      )
    };

    return (
      <Link to={getMyAccountPath()}>Minha Conta</Link>
    )
  }

  const handlePFLinks = () => {
    return (
      <ul className={styles.menu}>
        {/* <li>
          <Link to="/como-funciona">Doe sem gastar</Link>
        </li> */}
        <li>
          {renderMyAccountButton()}
        </li>
        <li>
          <Link to="/para-empresas" rel="noreferrer" target="_blank">
            Para empresas
          </Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
      </ul>
    )
  };

  const handlePJLinks = () => {
    return (
      <ul className={styles.menu}>
        <li>
          <Link to="/para-empresas">Pagina inicial</Link>
        </li>
        <li>
          <Link to="/para-empresas/mapeamento-tech">Mapeamento Tech</Link>
        </li>
        <li>
          {renderMyAccountButton()}
        </li>
        {renderMyPositionsButton()}
        <li>
          <Link to="/para-empresas/contato">Contato</Link>
        </li>
      </ul>
    )
  };

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.firstColumn}>
          <Link to="/">
            <img src={Logo} alt="Inklua" className={styles.logo} />
          </Link>
          <p className={styles.credits}>
            inklua.com © 2022
            <br />
            Todos os direitos reservados
          </p>
        </div>
        <div className={styles.secondColumn}>
          {userData && userData.type === 'PJ' && handlePJLinks()}
          {(!userData || userData.type !== 'PJ') && handlePFLinks()}
        </div>
        <div>
          <div className={styles.sociaMediaWrapper}>
            <SocialMediaLink url={Config.INSTAGRAM_URL}>
              <Instagram color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.FACEBOOK_URL}>
              <Facebook color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.LINKEDIN_URL}>
              <LinkedIn color="#fff" />
            </SocialMediaLink>
          </div>
          <p className={styles.links}>
            <Link to="/politica-de-privacidade">
              Politica de Privacidade
              <br /> &amp; Termos de Uso
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
